<template>
<!-- 劳动力统计分析 -->
 <div class="statistical_analysis">
   <!-- 头部 -->
   <div class="land_head">
   	<!-- 搜索 -->
   	<div class="select">
		<!-- 大棚选择 -->
		<el-select v-model="value1" clearable  placeholder="请选择" @change="typeChange">
		    <el-option
		      v-for="item in options1"
		      :key="item.value"
		      :label="item.label"
		      :value="item.value">
		    </el-option>
		</el-select>
		<el-select v-model="value2" clearable  placeholder="请选择" @change="landChange">
		    <el-option
		      v-for="item in options2"
		      :key="item.id"
		      :label="item.yard_name"
		      :value="item.id">
		    </el-option>
		</el-select>
   	</div>
   	<div class="land_title">劳动力统计分析</div>
   	<div class="back" @click="back">
   		<i class="el-icon-back"></i>
   		<span style="margin-left: 4px;">返回</span>
   	</div>
   </div>
   <div class="charts">
	   <div class="c_top">
		   <!-- 劳动量对比 -->
		   <div class="labor_charts">
		   	   <div class="title">
				   <div class="t_l"></div>
				   <div class="t_r">
				   	<div>当前各地块劳动量对比</div>
				   	<div class="line">
				   		<div class="line1">
				   			<div class="line3"></div>
				   		</div>
				   		<div class="line2">
				   			<div class="line4"></div>
				   		</div>
				   	</div>
				   </div>
		   	   </div>
			   <div id="myChart_labor1" ref='chart'></div>
		   </div>
		   <!-- 当前各地块出勤次数对比 -->
		   <div class="labor_charts">
		   	   <div class="title">
				   <div class="t_l"></div>
				   <div class="t_r">
				   	<div>当前各地块出勤次数对比</div>
				   	<div class="line">
				   		<div class="line1">
				   			<div class="line3"></div>
				   		</div>
				   		<div class="line2">
				   			<div class="line4"></div>
				   		</div>
				   	</div>
				   </div>
		   	   </div>
			   <div id="myChart_labor2" ref='chart'></div>
		   </div>
	   </div>
	   <div class="c_bottom">
	   		   <!-- 各月份累计劳动量趋势图 -->
	   		   <div class="labor_charts">
	   		   	   <div class="title">
					   <div class="t_l"></div>
					   <div class="t_r">
					   	<div>各月份累计劳动量趋势图</div>
					   	<div class="line">
					   		<div class="line1">
					   			<div class="line3"></div>
					   		</div>
					   		<div class="line2">
					   			<div class="line4"></div>
					   		</div>
					   	</div>
					   </div>
	   		   	   </div>
				   <div id="myChart_labor3" ref='chart'></div>
	   		   </div>
	   		   <!-- 各月份累计出勤次数趋势图 -->
	   		   <div class="labor_charts">
	   		   	   <div class="title">
					   <div class="t_l"></div>
					   <div class="t_r">
					   	<div>各月份累计出勤次数趋势图</div>
					   	<div class="line">
					   		<div class="line1">
					   			<div class="line3"></div>
					   		</div>
					   		<div class="line2">
					   			<div class="line4"></div>
					   		</div>
					   	</div>
					   </div>
	   		   	   </div>
				   <div id="myChart_labor4" ref='chart'></div>
	   		   </div>
	   </div>
   </div>
   
  

</div>
</template>

<script>
import { getVolumeCount,getVolumeMonthCount,getYardList } from '@/api/index'
// 引入所有 ECharts 中所有的图表和组件
import * as echarts from 'echarts';
export default {
  name: 'StatisticalAnalysisOfLaborForce',
  data () {
    return {
      fullHeight: '', // fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
	  options1:[
	  		{ value: '1',
	  		  label: '日光棚'
	  		}, {
	  		  value: '2',
	  		  label: '春秋棚'
	  		}, {
	  		  value: '3',
	  		  label: '大田'
	  		}                 
	  ],
	   value1:'',
	   value2:'',
	   options2:[],
	  timeId:[],//定时器集合
	  data1:[4193, 10188, 2491, 17850, 13979, 10137, 27327, 28757, 22190, 22460, 9795],//当前各地劳动量对比
	  xName1:[],////当前各地名称
	  xAxisName: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'] ,// 图三图四x轴名称
	  data2:[21, 20, 10, 5, 30, 14, 49, 47, 23, 30, 5],//当前各地出勤次数对比
	  data3:[21, 20, 10, 5, 30, 14, 49, 47, 23, 30, 5,88],//累计劳动总量趋势图
	  data4:[71,66, 18, 32, 24, 81, 58, 66, 25, 48, 56,66],//累计出勤次数总量趋势图
    }
  },
  mounted () {
	  console.log(111)
    // 加载数据表格
    // this.getProfile(1)
    // 获取chart盒子的宽度
    // this.fullWidth = `${document.querySelector('#myChart_labor1').clientWidth}`
	this.fullWidth = document.querySelector('#myChart_labor1').clientWidth
	console.log( this.fullWidth)
	const that = this
	 window.onresize = () => {
	   return (() => {
	     window.fullWidth = document.querySelector('#myChart_labor1').clientWidth
	     that.fullWidth = window.fullWidth
	   })()
	 }
	console.log(this.fullWidth)
	// this.drawLine1()
	// this.drawLine2()
	// this.drawLine3()
	// this.drawLine4()
	this.getChartsData1()
	this.getChartsData2()
  },
  destroyed() {
  	console.log('destroyed')
  	console.log(this.timeId)
  	// 清空该页面中所有的的定时器
  	for(let i=0;i<this.timeId.length;i++){
  	   clearInterval(this.timeId[i])
  	}
	// 销毁图表实列，释放echarts资源
	echarts.dispose(this.myChart1);
	echarts.dispose(this.myChart2);
	echarts.dispose(this.myChart3);
	echarts.dispose(this.myChart4);
	this.myChart1 = null;
	this.myChart2 = null;
	this.myChart3 = null;
	this.myChart4 = null;

  },
  methods: {
	// 获取柱状图数据
	getChartsData1 () {
	   console.log("获取柱状图数据")
	  // 获取表格数据
	  const data = {
		yard_type: this.value1,
	    yard_id: this.value2,
	  }
	  // 请求数据
	  getVolumeCount(data)
	    .then((res) => {
	      console.log(res)
		  console.log(res.data)
		  this.xName1=res.data.xName1
		  this.data1=res.data.data1
		  this.data2=res.data.data2
		  // this.drawLine1()
		  // this.drawLine2()
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 获取线状图统计数据
	getChartsData2 () {
	   console.log("获取线状图统计数据")
	  // 获取表格数据
	  const data = {
		yard_type: this.value1,
	    yard_id: this.value2,
	  }
	  // 请求数据
	  getVolumeMonthCount(data)
	    .then((res) => {
	      console.log(res)
		  console.log(res.data)
		  this.data3=res.data.data3
		  this.data4=res.data.data4
		  // this.drawLine3()
		  // this.drawLine4()
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	getLandList(type){
		console.log("获取场地")
		// 获取表格数据
		const data = {
		 type:type
		}
		// 请求数据
		getYardList(data)
		  .then((res) => {
				  console.log(res)
				  console.log(res.data)
				  this.options2=res.data
		  })
		  .catch((err) => {
		    console.log(err)
		  })
	},
	// 返回
	back(){
		this.$router.go(-1)
	},
	// 选择框事件，选中后去检索内容请求
	handleChange(value) {
	    console.log(value);
		console.log(this.value,this.times)
		// 更新表格内容
	},
	// 场地种类选择
	typeChange(e){
		console.log(e)
		this.getLandList(e)
	},
	landChange(e){
		console.log(e)
		this.getChartsData1()
		this.getChartsData2()
	},
	// 劳动量对比
	drawLine1() {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart1 = echarts.init(document.getElementById('myChart_labor1'));
			           this.myChart1.resize({
			             width: this.$refs.chart.clientWidth,
			             height: this.$refs.chart.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
					    animationDuration: 10000,
					    // title: {
					    //     text: "各地区农机作业量",
					    //     x:'center',
					    //     textStyle: {
					    //         color: '#1FFDFD',
					    //         fontWeight: 'normal',
					    //         fontSize: 16
					    //     }
					    // },
					    grid: {
					        left: '1%',
					        right: '1%',
					        bottom: '5%',
					        containLabel: true
					    },
					    
					    tooltip: {
					        show: "true",
					        trigger: 'axis',
					        axisPointer: { // 坐标轴指示器，坐标轴触发有效
					            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					        },
							label: {
							  backgroundColor: '#6a7985',
							}
					    },
						toolbox: {
						  feature: {
							magicType: { type: ['line', 'bar'] },
						    saveAsImage: {
								show:true,
						        backgroundColor :'balck',
								pixelRatio: 3
							}
						  },
						},
					    yAxis: {
					        name:'时间/H',
					        nameTextStyle: {
					            fontSize: 12,
					            fontFamily: 'Microsoft YaHei',
					            color: '#fff'
					        },
					        type: 'value',
					        axisTick: {
					            show: false
					        },
					        axisLine: {
					            show: true,
					            lineStyle: {
					                color: '#535A6B',
					            },
					        },
					        splitLine: {
					            show: false,
					            lineStyle: {
					                color: '#FFFFFF',
					            }
					        },
							axisLabel: {
							    fontSize: 12,
							    fontFamily: 'Microsoft YaHei',
							    color: "#FFFFFF",
							},
					    },
					    xAxis: [
					        {
					            type: 'category',
					            axisTick: {
					                show: false
					            },
					            axisLine: {
					                show: true,
					                lineStyle: {
					                    color: '#535A6B',
					                }
					            },
					            axisLabel: {
					            fontSize: 12,
					            fontFamily: 'Microsoft YaHei',
					            color: "#FFFFFF",
					            interval: 0,
					            rotate: 40,
					            margin: 10,
					            
					        },
					            data: this.xName1
					        }
					    
					    ],
					    series: [
					        {
					            name: '劳动量(H)',
					            type: 'bar',
					            itemStyle: {
					                normal: {
					                    show: true,
					                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
					                        offset: 0,
					                        color: '#D76547'
					                    }, {
					                        offset: 0.5,
					                        color: '#D78C47'
					                    },{
					                        offset: 1,
					                        color: '#DAC345'
					                    }]),
					                    barBorderRadius: 50,
					                    borderWidth: 0,
					                    borderColor: '#333',
					                }
					            },
					            label: {
					                normal: {
					                    show: true,
					                    position: 'top',
					                    textStyle: {
					                        color: '#fff'
					                    }
					                }
					            },
					            barWidth: '20%',
					            data: this.data1,
					        }
					    
					    ]
					   
					   
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart1.setOption(option);
					   // //动态循环显示数据节点
					   this.dataLableChange(this.myChart1,option,1)
						   
	},
	// 各地出勤次数对比
	drawLine2() {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart2 = echarts.init(document.getElementById('myChart_labor2'));
			           this.myChart2.resize({
			             width: this.$refs.chart.clientWidth,
			             height: this.$refs.chart.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
					    animationDuration: 10000,
					    // title: {
					    //     text: "各地区农机作业量",
					    //     x:'center',
					    //     textStyle: {
					    //         color: '#1FFDFD',
					    //         fontWeight: 'normal',
					    //         fontSize: 16
					    //     }
					    // },
					    grid: {
					        left: '1%',
					        right: '1%',
					        bottom: '5%',
					        containLabel: true
					    },
					    
					    tooltip: {
					        show: "true",
					        trigger: 'axis',
					        axisPointer: { // 坐标轴指示器，坐标轴触发有效
					            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
					        }
					    },
						toolbox: {
						  feature: {
							magicType: { type: ['line', 'bar'] },
						    saveAsImage: {
								show:true,
						        backgroundColor :'balck',
								pixelRatio: 3
							}
						  },
						},
					    yAxis: {
					        name:'次数/次',
					        nameTextStyle: {
					            fontSize: 12,
					            fontFamily: 'Microsoft YaHei',
					            color: '#fff'
					        },
					        type: 'value',
					        axisTick: {
					            show: false
					        },
					        axisLine: {
					            show: true,
					            lineStyle: {
					                color: '#535A6B',
					            },
					        },
					        splitLine: {
					            show: false,
					            lineStyle: {
					                color: '#FFFFFF',
					            }
					        },
							axisLabel: {
							    fontSize: 12,
							    fontFamily: 'Microsoft YaHei',
							    color: "#FFFFFF",
							},
					    },
					    xAxis: [
					        {
					            type: 'category',
					            axisTick: {
					                show: false
					            },
					            axisLine: {
					                show: true,
					                lineStyle: {
					                    color: '#535A6B',
					                }
					            },
					            axisLabel: {
					            fontSize: 12,
					            fontFamily: 'Microsoft YaHei',
					            color: "#FFFFFF",
					            interval: 0,
					            rotate: 40,
					            margin: 10,
					            
					        },
					            data: this.xName1
					        }
					    
					    ],
					    series: [
					        {
					            name: '出勤次数(次)',
					            type: 'bar',
					            itemStyle: {
					                normal: {
					                    show: true,
					                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
					                        offset: 0,
					                        color: '#00FFE6'
					                    }, {
					                        offset: 1,
					                        color: '#007CC6'
					                    }]),
					                    barBorderRadius: 50,
					                    borderWidth: 0,
					                    borderColor: '#333',
					                }
					            },
					            label: {
					                normal: {
					                    show: true,
					                    position: 'top',
					                    textStyle: {
					                        color: '#fff'
					                    }
					                }
					            },
					            barWidth: '20%',
					            data: this.data2,
					        }
					    
					    ]
					   
					   
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart2.setOption(option);
					   // //动态循环显示数据节点
					   this.dataLableChange(this.myChart2,option,2)
						   
	},
	// 各月份累计劳动量趋势图
	drawLine3() {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart3 = echarts.init(document.getElementById('myChart_labor3'));
			           this.myChart3.resize({
			             width: this.$refs.chart.clientWidth,
			             height: this.$refs.chart.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
					    animationDuration: 10000,
					    // title: {
					    //     text: "各地区农机作业量",
					    //     x:'center',
					    //     textStyle: {
					    //         color: '#1FFDFD',
					    //         fontWeight: 'normal',
					    //         fontSize: 16
					    //     }
					    // },
					    grid: {
					        left: '1%',
					        right: '5%',
					        bottom: '5%',
					        containLabel: true
					    },
					    
					    tooltip: {
					        show: "true",
					        trigger: 'axis',
					        axisPointer: { // 坐标轴指示器，坐标轴触发有效
					            type: 'cross' // 默认为直线，可选为：'line' | 'shadow'
					        }
					    },
						 toolbox: {
						   feature: {
						 	magicType: { type: ['line', 'bar'] },
						     saveAsImage: {
						 		show:true,
						         backgroundColor :'balck',
						 		pixelRatio: 3
						 	}
						   },
						 },
					    yAxis: {
					        name:'时间/H',
					        nameTextStyle: {
					            fontSize: 12,
					            fontFamily: 'Microsoft YaHei',
					            color: '#fff'
					        },
					        type: 'value',
					        axisTick: {
					            show: false
					        },
					        axisLine: {
					            show: true,
					            lineStyle: {
					                color: '#535A6B',
					            },
					        },
					        splitLine: {
					            show: false,
					            lineStyle: {
					                color: '#FFFFFF',
					            }
					        },
							axisLabel: {
							    fontSize: 12,
							    fontFamily: 'Microsoft YaHei',
							    color: "#FFFFFF",
							},
					    },
					    xAxis: [
					        {
					            type: 'category',
					            axisTick: {
					                show: false
					            },
					            axisLine: {
					                show: true,
					                lineStyle: {
					                    color: '#535A6B',
					                }
					            },
					            axisLabel: {
					            fontSize: 12,
					            fontFamily: 'Microsoft YaHei',
					            color: "#FFFFFF",
					        },
					            data: this.xAxisName
					        }
					    
					    ],
					    series: [
					        {
					            name: '累计劳动总量(H)',
					            type: 'line',
								areaStyle: {
									//改变区域渐变颜色
									normal: {
									    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									            offset: 0,
									            color: 'rgba(215,140,71,1)'
									        }, {
									            offset: 1,
									            color: 'rgba(215,140,71,0.5)'
									        }]),            
									}									
								},
					            itemStyle: {
					                normal: {
					                    show: true,
					                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
					                        offset: 0,
					                        color: 'rgba(215,140,71,1)'
					                    }, {
					                        offset: 1,
					                        color: '#D76547'
					                    }]),
					                    barBorderRadius: 50,
					                    borderWidth: 0,
					                    borderColor: '#333',
					                }
					            },
					            label: {
					                normal: {
					                    show: true,
					                    position: 'top',
					                    textStyle: {
					                        color: '#fff'
					                    }
					                }
					            },
					            barWidth: '20%',
					            data: this.data3,
							    markPoint: {
							          data: [
							            { type: 'max', name: 'Max' },
							            { type: 'min', name: 'Min' }
							          ]
							        },
							   markLine: {
							       data: [{ type: 'average', name: 'Avg' }]
							   }
					        }
					    
					    ]
					   
					   
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart3.setOption(option);
					   // //动态循环显示数据节点
					   this.dataLableChange(this.myChart3,option,3)
						   
	},
	// 各月份累计劳动量趋势图
	drawLine4() {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart4 = echarts.init(document.getElementById('myChart_labor4'));
			           this.myChart4.resize({
			             width: this.$refs.chart.clientWidth,
			             height: this.$refs.chart.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
					    animationDuration: 10000,
					    // title: {
					    //     text: "各地区农机作业量",
					    //     x:'center',
					    //     textStyle: {
					    //         color: '#1FFDFD',
					    //         fontWeight: 'normal',
					    //         fontSize: 16
					    //     }
					    // },
					    grid: {
					        left: '1%',
					        right: '5%',
					        bottom: '5%',
					        containLabel: true
					    },
					    
					    tooltip: {
					        show: "true",
					        trigger: 'axis',
					        axisPointer: { // 坐标轴指示器，坐标轴触发有效
					            type: 'cross' // 默认为直线，可选为：'line' | 'shadow'
					        }
					    },
						 toolbox: {
						   feature: {
						 	magicType: { type: ['line', 'bar'] },
						     saveAsImage: {
						 		show:true,
						         backgroundColor :'balck',
						 		pixelRatio: 3
						 	}
						   },
						 },
					    yAxis: {
					        name:'时间/H',
					        nameTextStyle: {
					            fontSize: 12,
					            fontFamily: 'Microsoft YaHei',
					            color: '#fff'
					        },
					        type: 'value',
					        axisTick: {
					            show: false
					        },
					        axisLine: {
					            show: true,
					            lineStyle: {
					                color: '#535A6B',
					            },
					        },
					        splitLine: {
					            show: false,
					            lineStyle: {
					                color: '#FFFFFF',
					            }
					        },
							axisLabel: {
							    fontSize: 12,
							    fontFamily: 'Microsoft YaHei',
							    color: "#FFFFFF",
							},
					    },
					    xAxis: [
					        {
					            type: 'category',
					            axisTick: {
					                show: false
					            },
					            axisLine: {
					                show: true,
					                lineStyle: {
					                    color: '#535A6B',
					                }
					            },
					            axisLabel: {
					            fontSize: 12,
					            fontFamily: 'Microsoft YaHei',
					            color: "#FFFFFF",
					        },
					            data: this.xAxisName
					        }
					    
					    ],
					    series: [
					        {
					            name: '累计出勤次数（次）',
					            type: 'line',
								areaStyle: {
									//改变区域渐变颜色
									normal: {
									    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									            offset: 0,
									            color: 'rgba(0,255,230,1)'
									        }, {
									            offset: 1,
									            color: 'rgba(0,255,230,0.2)'
									        }]),            
									}									
								},
					            itemStyle: {
					                normal: {
					                    show: true,
					                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
					                        offset: 0,
					                        color: '#00FFE6'
					                    }, {
					                        offset: 1,
					                        color: '#007CC6'
					                    }]),
					                    barBorderRadius: 50,
					                    borderWidth: 0,
					                    borderColor: '#333',
					                }
					            },
					            label: {
					                normal: {
					                    show: true,
					                    position: 'top',
					                    textStyle: {
					                        color: '#fff'
					                    }
					                }
					            },
					            barWidth: '20%',
					            data: this.data4,
							    markPoint: {
							          data: [
							            { type: 'max', name: 'Max' },
							            { type: 'min', name: 'Min' }
							          ]
							        },
							   markLine: {
							       data: [{ type: 'average', name: 'Avg' }]
							   }
					        }
					    
					    ]
					   
					   
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart4.setOption(option);
					   // //动态循环显示数据节点
					   this.dataLableChange(this.myChart4,option,4)
						   
	},
	// 动态循环显示数据节点方法
	dataLableChange(myChart,option,i){
			   // console.log('shenme')
			   //动态循环显示数据节点
			   var app = {
			       currentIndex: -1,
			   };
			   this.timeId[i]=setInterval(function() {
			   	   // console.log('start'+i)
			       var dataLen = option.series[0].data.length;
			       // 取消之前高亮的图形
			       myChart.dispatchAction({
			           type: 'downplay',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			       app.currentIndex = (app.currentIndex + 1) % dataLen;
			   
			       // 高亮当前图形
			       myChart.dispatchAction({
			           type: 'highlight',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex,
			       });
			   
			       // 显示 tooltip
			       myChart.dispatchAction({
			           type: 'showTip',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			   
			   }, 5000); 
			   
	},
  },
  // 监听屏幕变化
  watch: {
    // 如果 `fullWidth ` 发生改变，这个函数就会运行
    fullWidth: function (val) {
		console.log('变化')
      if (!this.timer) {
        this.fullWidth = val
        this.drawLine1()
		this.drawLine2()
		this.drawLine3()
		this.drawLine4()
        console.log(val)
        this.timer = true
        const that = this
        setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
          that.timer = false
        }, 100)
      }
    },
	data1: function (val) {
		console.log('data1变化')
	  if (!this.timer) {
	    this.drawLine1()
	    console.log(val)
	    this.timer = true
	    const that = this
	    setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
	      that.timer = false
	    }, 100)
	  }
	},
	data4: function (val) {
	  console.log('data4变化')
	  this.drawLine4()
	},
	data2: function (val) {
		console.log('data2变化')
		this.drawLine2()
	},
	data3: function (val) {
		console.log('data3变化')
		this.drawLine3()
	},

  }
}
</script>
<style scoped>
  .statistical_analysis{
	width: 100%;
	height: 100%;
	padding:0 30px 30px 30px;
	box-sizing: border-box;
	overflow: auto;
  }
  .land_head{
  	  width: 100%;
  	  height: 78px;
  	  margin-bottom: 30px;
  	  display: flex;
  	  align-items: flex-end;
  	  justify-content: start;
  	  position: relative;
  }
  .select{
  	  /* width: 600px; */
  	  height: 48px;
	  display: flex;
	  justify-content: start;
	  align-items: center;
  	  /* background-color: #1CCA81; */
  }
  .land_title{
  	  width: 554px;
  	  height: 78px;
  	  line-height: 78px;
  	  font-size: 28px;
  	  font-family: PingFang SC;
  	  /* font-weight: bold; */
  	  color: #FFFFFF;
  	  margin-left: 100px;
  	  letter-spacing: 2px;
  	  background: url(../../../assets/title_bg.png) no-repeat center;
  	  background-size: contain;
	  position: absolute;
	  left: 50%;
	  transform: translateX(-50%);
  }
  .back{
  	  position: absolute;
  	  right: 0;
  	  bottom: 0;
  	  width: 135px;
  	  height: 48px;
  	  line-height: 48px;
  	  font-size: 14px;
  	  font-family: PingFang SC;
  	  letter-spacing: 2px;
  	  /* font-weight: bold; */
  	  color: #FFFFFF;
  	 background: url(../../../assets/back_bg.png) no-repeat center;
  	 background-size: cover;
  	  border: 1px solid #4B5052;
  	  display: flex;
  	  align-items: center;
  	  justify-content: center;
	  cursor: pointer;
  }
  .back i{
  	  font-size: 18px;
  }
  .title{
      width: 100%;
      height: 42px;
      display: flex;
      align-items: flex-end;
      aljustify-content: start;
      text-align: left;
  	  margin-top: 0;
	  margin-bottom: 2%;
    }
    .t_l{
    	  width: 33px;
    	  height: 34px;
    	  background: url(../../../assets/jiankong/quan_o.png);
    	  background-size: contain;
    	  margin-right: 14px;
    }
    .t_r{
    	  width: calc(100% - 47px);
    	  font-size: 16px;
    	  font-family: PingFang SC;
    	  font-weight: bold;
    	  color: #BFF2F0;
    	  height: 42px;
    	  line-height: 42px;
    }
    .line{
    	  width: 100%;
    	  height: 1px;
    	  display: flex;
  	  justify-content: space-between;
  	  align-items: center;
    }
    .line1{
  	  width: 84%;
  	  height: 1px;
  	  background: #535A6B;
    }
    .line2{
  	  width: 15%;
  	  height: 1px;
  	  background: #535A6B;
  	  text-align: center;
    }
    .line3{
  	width: 90%;
  	height: 1px; 
  	  background: #36CFC9;
    }
    .line4{
  	  position: relative;
  	  left: 50%;
  	  transform: translateX(-50%);
  	  width: 15px;
  	  height: 1px;
  	  background: #36CFC9;
    }
	.del{
		width: 60px;
		height: 24px!important;
		line-height: 24px!important;
		background: rgba(215,140,71, 0.2);
		border: 1px solid #D78C47;
		color: #D78C47!important;
		padding: 0;
	}
	.charts{
		width: 100%;
		height: calc(100% - 108px);
	}
	.c_top,.c_bottom{
		width: 100%;
		height: calc(50% - 15px);
		/* background-color: #173660; */
		margin-bottom: 30px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.labor_charts{
		width:calc(50% - 15px) ;
		height: 100%;
		background-color: rgba(10,23,56,0.7);
		padding: 0 20px 20px 20px;
		box-sizing: border-box;
	}
	
  /deep/.el-select{
	  margin-right: 30px;
  }
  /deep/.el-select .el-input__inner{
  	  height: 48px;
  	  width: 262px;
  	  background-color: rgba(11,19,38,0.5);
  	  border: 1px solid #36CFC9;
  	  color: #FFFFFF !important;
  }
  /deep/.el-select .el-input.is-focus .el-input__inner{
	  border: 1px solid #36CFC9;
  }
  /deep/.el-input__inner{
	  height: 48px;
	  width: 262px;
	  background-color: rgba(11,19,38,0.5);
	  border: 1px solid #36CFC9;
	  color: #FFFFFF !important;
  }
  /deep/.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner{
	  border-color: #36CFC9;
  }
  /deep/.el-cascader .el-input .el-input__inner:hover, .el-range-editor.is-active, .el-range-editor.is-active:hover{
  	  border-color: #36CFC9;
  }
  /deep/.el-range-editor.el-input__inner{
	  margin-left: 30px;
  }
  /deep/.el-input__suffix{
	  color: #FFFFFF !important;
  }
 
 #myChart_labor1{
    width: 100%;
    height:calc(100% - 30px);
  }
  #myChart_labor2{
    width: 100%;
    height:calc(100% - 30px);
   }
  #myChart_labor3{
     width: 100%;
     height:calc(100% - 30px);
    }
   #myChart_labor4{
	  width: 100%;
	  height:calc(100% - 30px);
	 }
</style>



